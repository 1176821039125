
export const getVersion = function() { 
    var MAJOR=3;
    var MINOR=2;
    var RELEASE=0;
    var COMMIT="15f4da7d";
    var BUILD="36";
    return MAJOR + "." + MINOR + "." + RELEASE + "." + BUILD + "-" + COMMIT; 
}

export default getVersion;
